/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr } from "./newhomesdevelopmentmaputils"
 
 const NewHomesSeoResults = ({ description, lang, meta, title, searchParams, location }) => {
 
   const {areas, bedrooms, pType, type, price, commType } = searchParams;
   const areaname = searchParams.areas
   const pricerange = searchParams.price
   const minprice = searchParams.price.min
   const maxprice = searchParams.price.max
   const propType = searchParams.type
   const bedRooms = searchParams.bedrooms
   
   const actionStr = ACTION_TYPES[pType];
   const priceStr = getPriceStr(price)
 
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let propertyType = ""
 
   if(type == "") {
     propertyType = "New Homes"
   } else {
     propertyType = "New Homes " + capitalize(type)
   }
 
   let bedroomCount = ""
 
   if (bedRooms) {
     bedroomCount = bedRooms + " + Bedroom "
   }
   let desc = "";
   desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
   if(pricerange) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(propType){
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(bedRooms) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(pricerange && propType) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(pricerange && bedRooms) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(propType && bedRooms) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(minprice && propType && bedRooms) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
   if(maxprice && propType && bedRooms) {
    desc = "Discover a wide range of "+ bedroomCount + propertyType + actionStr + capitalize(areaname) + priceStr +" and the surrounding areas with Orlando Reid. Refine your search using the filters above and for more information about new developments in and around Manchester please contact Orlando Reid Estate Agents Manchester."
}
 
     //
     let titlePropertyType = ""
 
     if (type) {
       titlePropertyType = `New Homes ${capitalize(type)} `;
     } else {
       titlePropertyType = ` New Homes  `;
     }
 
     // if(type == "") {
     //     titlePropertyType = "New Homes"
     // } else if(type == "apartments") {
     //     titlePropertyType = "Apartments"
     // } else if(type == "houses") {
     //     titlePropertyType = "Houses"
     // } else if(type == "maisonette") {
     //     titlePropertyType = "Maisonette"
     // } else if(type == "property") {
     //     titlePropertyType = "Property"
     // } else if(type == "commercial") {
     //     titlePropertyType = "Commercial"
     // } else {
     //     titlePropertyType = type
     // }
 
     let bedroomType = ""
 
     if ( bedrooms ) {
       bedroomType = bedrooms + ' + Bedroom '
     }
 
     let descTitle = bedroomType + capitalize(titlePropertyType) + actionStr + capitalize(areas) + priceStr
     //
 
   // console.log("Props Desc => ", desc);
 
   description = desc
 
   let metaItems = [
     {
       name: `description`,
       content: description,
     },
     {
       property: `og:title`,
       content: descTitle,
     },
     {
       property: `og:description`,
       content: description,
     },
     {
       property: `og:type`,
       content: `website`,
     },
     {
       name: `twitter:card`,
       content: `summary`,
     },
     {
       name: `twitter:creator`,
       content: `Orlando Reid`,
     },
     {
       name: `twitter:title`,
       content: descTitle,
     },
     {
       name: `twitter:description`,
       content: description,
     },
   ].concat(meta);
 
 
   // set no index for result pagination
   let hasPagination = false;
   if (location !== undefined && location.pathname) {
     let pageStr = location.pathname.split('page-');
     hasPagination = pageStr[1] || false;
   }
   if ( hasPagination ) {
     metaItems.push({
       name: `robots`,
       content: `noindex`,
     })
   }
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={descTitle}
       titleTemplate={`%s | Orlando Reid`}
       meta={metaItems}
     />
   )
 }
 
 NewHomesSeoResults.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 NewHomesSeoResults.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default NewHomesSeoResults
 